import React, { useContext } from 'react';
import t from 'Helpers/translations';
import { LanguageContext } from 'I18n/LanguageContext';
import Search from 'Components/search';
import { trackingEvent } from 'Components/tracking';
import style from './error-message.module.scss';

export const ErrorMessage = () => {

    const { basePath } = useContext(LanguageContext);

    trackingEvent('event', {
        category: 'Error',
        action: 'Shown',
        label: `${window.location.pathname}${window.location.search}`
    });

    return <div className={style["error-message"]}>
        <h1>{t('error.title')}</h1>
        <div className={style.content}>
            <p className={style.message}>{t('error.message')}</p>
            <p className={style.code}>{t('error.code', 404)}</p>
            <p className={style.recovery}>{t('error.recovery', '<a href="' + basePath + '/">Home</a>')}</p>
            <p className={style.email}>
                {t('error.email', '<a href="mailto:feedback@quasarstats.com?subject=Quasar Stats Error">feedback@quasarstats.com</a>')}
            </p>
            <div className={style.search}>
                <Search parent="home" />
            </div>
        </div>
    </div>;
};

export default ErrorMessage;
