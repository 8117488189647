import React from 'react';
import getLanguage from 'Helpers/get-language-from-url';
import { supportedLanguages, defaultLanguage } from 'Helpers/supported-languages';

export const languages = {
    en: async () => await import('I18n/data/en-gb.json'),
    ga: async () => await import('I18n/data/ie-ie.json'),
    it: async () => await import('I18n/data/it-it.json'),
    ru: async () => await import('I18n/data/ru-ru.json')
};

const current = supportedLanguages.find(lang => lang.locale === getLanguage(window.location.href)) || {};

export const LanguageContext = React.createContext({
    locale: current.locale || defaultLanguage,
    basePath: current.basePath || `/${defaultLanguage}`,
    languageIso: current.language || defaultLanguage
});
