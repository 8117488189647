import FormatDate from 'Helpers/format-date';

class EntityModel {

    formatDate = new FormatDate(
        'en-gb'
    );

    data = [];

    setData(data) {
        this.source = JSON.parse(JSON.stringify(data));
        this.data = this.source.map(item => ({
            title: item.type === 'tournament' ? item.location : item.name,
            extra: item.type === 'tournament' ? this.formatDate.getDate(item.date) : item.fullname,
            id: item.type === 'team' ? item.name : item.id,
            type: item.type,
            name: item.type === 'tournament'
                ? `${item.location} (${this.formatDate.getDate(item.date)})`
                : item.fullname || item.name
        }));
    }

    filteredList(term, type, exclude) {
        const data = type
            ? this.data.filter(item => item.type === type.slice(0, -1)).filter(item => item.id !== exclude)
            : this.data.filter(item => item.id !== exclude);

        if (!term && !type) {
            return data || null;
        }

        const search = term.toLowerCase();
        return data.filter(item => item.title.toLowerCase().includes(search) ||
            item.extra && item.extra.toLowerCase().includes(search));
    }

    get list() {
        return this.data || null;
    }
}

export default EntityModel;
