import React from 'react';

let localisedText = {};

export const setCopies = (text) => {
    localisedText = text;
};

export default function translate(key, ...args) {

    let val = localisedText[key] || null;

    if (key.includes('.')) {
        const paths = key.split('.');
        val = localisedText[paths[0]][paths[1]];
    }
    if (!val) {
        console.warn(`No i18n value found for ${key}`);
        return null;
    }
    if (args) {
        args.forEach((arg, i) => {
            val = val.replace(new RegExp(`{[${i}]+}`, 'g'), `${arg}`);
        });
        return (/<.+>/).test(args.join(''))
            ? <span className="i18n" dangerouslySetInnerHTML={{ __html: val }} />
            : val;
    }
    return val;
}
