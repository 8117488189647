import React, { useContext } from 'react';
import { supportedLanguages } from 'Helpers/supported-languages';
import { LanguageContext } from 'I18n/LanguageContext';
import LanguageLink from 'Components/language-link';
import style from './language-list.module.scss';

const LanguageList = ({ currentPath,
    languages = supportedLanguages }) => <div className={style.languages}>
    <ul className={style.options}>
        {languages.map(language =>
            <LanguageItem key={language.locale} locale={language.locale} path={currentPath} />
        )}
    </ul>
</div>;

const LanguageItem = ({ path, locale }) => {
    const context = useContext(LanguageContext);
    const active = locale === context.locale;

    return <li key={locale} className={`${active ? style.selected : ''}`}>
        <LanguageLink locale={locale} path={path} />
    </li>;
};

export default React.memo(LanguageList);
