import React from 'react';
import style from './input-field-text.module.scss';

const InputFieldText = ({ onChange, onBlur, reference, ariaLabel, placeholder, value, type }) => <input
    className={`${style.text}${type ? ` ${style[type]}` : ''}`}
    ref={reference}
    onChange={onChange}
    onBlur={onBlur}
    type="text"
    aria-label={ariaLabel}
    placeholder={placeholder}
    value={value} />;

export default InputFieldText;
