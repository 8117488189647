import React from 'react';
import t from 'Helpers/translations';
import style from './footer.module.scss';

export const Footer = () => <div className={style.footer}>
    <p>
        <a href="mailto:feedback@quasarstats.com?subject=Quasar Stats Feedback">
            {t('feedback', `<span class="${style.link}">feedback@quasarstats.com</span>`)}
        </a>
    </p>
</div>;

export default Footer;
