const getLanguage = (url) => {
    if (!getLanguage.cache) {
        getLanguage.cache = {};
    }
    if (getLanguage.cache[url] !== undefined) {
        return getLanguage.cache[url];
    }

    const path = window.location.pathname.match(/^\/(?:[a-zA-Z]{2})\//g);
    getLanguage.cache[url] = path ? path[0].replace(/\//g, '') : '';
    return getLanguage.cache[url];
};

export default getLanguage;
