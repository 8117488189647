import React, { useContext } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { LanguageContext } from 'I18n/LanguageContext';

export const IntlLink = (props) => {
    const { basePath } = useContext(LanguageContext);
    const href = `${basePath}${props.to}`;

    return <Link {...props}
        to={href}>
        {props.children}
    </Link>;
};

export const IntlNavLink = (props) => {
    const { basePath } = useContext(LanguageContext);
    const href = `${basePath}${props.to}`;

    return <NavLink {...props}
        to={href}>
        {props.children}
    </NavLink>;
};
