import React, { Fragment } from 'react';
import { setCopies } from 'Helpers/translations';
import { languages, LanguageContext } from 'I18n/LanguageContext';

export default class IntlProvider extends React.Component {
    static contextType = LanguageContext;

    constructor(props, context) {
        super(props, context);

        this.state = {
            language: false
        };
        this.mounted = false;
    }

    async componentWillMount() {
        const iso = this.context.languageIso;
        document.getElementsByTagName('html')[0].lang = iso;
        const text = await languages[iso]();
        if (this.mounted && text) {
            setCopies(text);
            this.setState({
                language: true
            });
        }
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        if (!this.state.language) {
            return null;
        }
        return <Fragment>
            {this.props.children}
        </Fragment>;
    }
}
