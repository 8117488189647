import React from 'react';
import { withRouter } from 'react-router-dom';
import { IntlLink as Link } from 'I18n/intl-link';
import t from 'Helpers/translations';
import LanguageSelector from 'Components/language-selector';
import logo from './img/q-logo.svg';
import style from './header.module.scss';

const Header = ({ location }) => {
    const { pathname, search } = location;

    return <header className={style.header} role="banner">
        <Link to={`/`} tabIndex="-1">
            <img src={logo} alt={t('quasarQ')} width="31" height="32" />
            <span>&nbsp;{t('stats')}</span>
        </Link>
        <LanguageSelector currentPath={pathname + search} />
    </header>;
};

export default withRouter(Header);
