import React, { PureComponent, Fragment } from 'react';
import t from 'Helpers/translations';
import { isModalOpen as checkModal } from 'Helpers/modal-urls';
import { LanguageContext } from 'I18n/LanguageContext';
import Modal from 'Components/modal';
import LanguageList from 'Components/language-list';
import LanguageLink from 'Components/language-link';
import style from './language-selector.module.scss';

class LanguageSelector extends PureComponent {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.modalId = "modal-language";

        this.state = {
            isModalOpen: checkModal(this.modalId)
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }

    closeModal() {
        if (this.mounted) {
            this.setState({
                isModalOpen: false
            });
            this.button.blur();
        }
    }

    render() {
        const { isModalOpen } = this.state;
        const { currentPath } = this.props;
        const content = <LanguageList close={this.closeModal} parent="modal" currentPath={currentPath} />;
        const header = <h1>{t('language.select')}</h1>;
        const { locale } = this.context;

        return <Fragment>
            <button ref={node => (this.button = node)}
                className={style.selector}
                onClick={this.toggleModal} aria-label={t('language.select')} value={locale}>
                <LanguageLink span locale={locale} />
            </button>

            {isModalOpen && <Modal
                id={this.modalId}
                addToHistory={false}
                show={isModalOpen}
                close={this.closeModal}
                header={header}
                content={content}
                feedback
            />}
        </Fragment>;
    }
}

export default LanguageSelector;
