import React, { PureComponent } from 'react';
import { urls } from 'Helpers/api';
import EntityModel from './entity-model';
import style from './entity-list.module.scss';

class EntityList extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            selected: props.preselected || false
        };

        this.model = new EntityModel();
        this.select = this.select.bind(this);
        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData(urls.list);
    }

    componentWillReceiveProps(nextProps) {
        const { onSelect, instance } = this.props;
        if (nextProps.term && this.state.selected && (nextProps.term !== this.props.term)) {
            this.setState({
                selected: false
            });
            onSelect && onSelect(null, '', instance);
        }
    }

    componentWillUnmount() {
        this.setState({
            mounted: false
        });
    }

    getData(url) {
        fetch(url)
            .then(response => response.json())
            .then(d => {
                if (this.state.loading === true) {
                    this.model.setData(d);
                    this.setState({
                        loading: false
                    });
                }
            });
    }

    select(id, name, type) {
        const { onSelect, instance } = this.props;
        this.setState({
            selected: true
        });
        onSelect && onSelect(id, name, instance, type);
    }

    render() {
        const { term, size = 12, type, exclude } = this.props;
        const { loading, selected } = this.state;

        if (loading || !this.model.list || selected || !term) {
            return null;
        }

        const list = this.model.filteredList(term, type, exclude);

        if (list.length < 1) {
            return null;
        }

        return <ul className={style.list}>
            {list.slice(0, size)
                .map((item, i) => {
                    const { title, extra, id, name } = item;

                    return <li
                        tabIndex="0"
                        className={style[item.type]}
                        key={`item-${i}`}
                        onClick={() => this.select(id, name, item.type)}
                        onKeyPress={() => this.select(id, name, item.type)}>
                        <h3>{title}</h3>
                        {extra && <span>{extra}</span>}
                    </li>;
                })
            }
        </ul>;
    }
}

export default EntityList;
