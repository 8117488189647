import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import t from 'Helpers/translations';
import { removeModalParam, isModalOpen as checkModal } from 'Helpers/modal-urls';
import { IntlNavLink as NavLink } from 'I18n/intl-link';
import Modal from 'Components/modal';
import Search from 'Components/search';
import { homeImg, searchImg, playersImg, teamsImg, tournamentsImg } from './icons';
import style from './menu.module.scss';

class Menu extends PureComponent {
    constructor(props) {
        super(props);

        this.modalId = "modal-search";

        this.state = {
            isModalOpen: checkModal(this.modalId)
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.mounted = false;
    }

    componentWillReceiveProps() {
        this.setState({
            isModalOpen: checkModal(this.modalId)
        });
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    toggleModal() {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }

    closeModal() {
        if (this.mounted) {
            this.setState({
                isModalOpen: false
            });
            this.button.blur();
            removeModalParam();
        }
    }

    render() {
        const { isModalOpen } = this.state;
        const content = <Search close={this.closeModal} parent="modal" />;
        const header = <h1>{t('search')}</h1>;

        return <nav role="navigation">
            <ul className={style.menu}>
                <li>
                    <NavLink exact={true} to={`/`} activeClassName="active" aria-label={t('home')}>
                        <img src={homeImg} alt="" width="24" />
                        <span>{t('home')}</span>
                    </NavLink>
                </li>
                <li>
                    <button ref={node => (this.button = node)}
                        onClick={this.toggleModal} aria-label={t('search')}>
                        <img src={searchImg} alt="" width="24" />
                        <span>{t('search')}</span>
                    </button>
                </li>
                <li>
                    <NavLink to={`/players`} activeClassName="active" aria-label={t('players')}>
                        <img src={playersImg} alt="" width="24" />
                        <span>{t('players')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/teams`} activeClassName="active" aria-label={t('teams')}>
                        <img src={teamsImg} alt="" width="24" />
                        <span>{t('teams')}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/tournaments`} activeClassName="active" aria-label={t('tournaments')}>
                        <img src={tournamentsImg} alt="" width="24" />
                        <span>{t('tournaments')}</span>
                    </NavLink>
                </li>
            </ul>
            {isModalOpen && <Modal
                id={this.modalId}
                show={isModalOpen}
                close={this.closeModal}
                header={header}
                content={content}
                feedback
            />}
        </nav>;
    }
}

export default withRouter(Menu);
