const baseUrl = 'https://api.quasarstats.com/';

const urls = {
    latest: `${baseUrl}tournaments/latest`,
    players: `${baseUrl}players/`,
    teams: `${baseUrl}teams/`,
    tournaments: `${baseUrl}tournaments/`,
    stats: `${baseUrl}stats/`,
    list: `${baseUrl}entities/list`
};

export {
    urls
};
