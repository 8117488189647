import React from 'react';
import { IntlLink as Link } from 'I18n/intl-link';
import style from './cta.module.scss';

const Cta = ({ url, label, button = false, type, onClick, children, value, extraClass, icon, disabled = false }) => {

    const href = encodeURI(url).replace(/'/g, '%27');
    const classes = [
        style.cta,
        style[type],
        extraClass && style[extraClass],
        icon && style.icon,
        icon && style[icon]
    ].filter(Boolean).join(' ');

    const sharedProps = {
        className: classes,
        onClick: onClick,
        'aria-label': label
    };

    if (button) {
        return <button {...sharedProps}
            disabled={disabled}
            value={value}>
            {children}
        </button>;
    }

    return <Link {...sharedProps}
        to={href}>
        {children}
    </Link>;

};

export default React.memo(Cta);
