import React, { useContext } from 'react';
import t from 'Helpers/translations';
import { supportedLanguages } from 'Helpers/supported-languages';
import { LanguageContext } from 'I18n/LanguageContext';
import style from './language-link.module.scss';

const imgPath = 'https://assets.quasarstats.com/images/flags/';

const LanguageLink = ({ locale, path = '/', span = false }) => {
    const { basePath } = useContext(LanguageContext);
    const thisLang = supportedLanguages.find(lang => lang.locale === locale) || null;

    if (!thisLang) {
        return null;
    }

    const img = `${imgPath}${thisLang.flag}.svg`;
    const newPath = path.match(/\/[a-zA-Z]{2}\//g) ? path.replace(basePath, thisLang.basePath) : `${thisLang.basePath}${path}`;

    if (span) {
        return <span
            className={style.flag}
            style={{ color: '#fff', backgroundImage: `url(${img})` }} />;
    }

    return <a href={`${newPath}`} hrefLang={thisLang.language}>
        <span className={style.text}>{t(`language.${locale}`)}</span>
        <span className={style.flag} style={{ color: '#fff', backgroundImage: `url(${img})` }} />
    </a>;
};

export default React.memo(LanguageLink);
