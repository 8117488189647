import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends PureComponent {
    componentDidUpdate(prevProps) {
        // scroll to top if we navigate,
        // but DON'T do it if we are opening or closing a modal
        if (!prevProps.location.search.includes('modal=') && !this.props.location.search.includes('modal=')) {
            const root = window.document.getElementById('root');
            root.scrollTo
                ? root.scrollTo(0, 0)
                : root.scrollTop = 0;
            window.scrollTo
                ? window.scrollTo(0, 0)
                : window.scrollTop = 0;
        }
    }

    render() {
        return null;
    }
}

export default withRouter(ScrollToTop);
