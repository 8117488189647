export default class FormatDate {
    constructor(locale = 'en-gb', format) {
        this.locale = locale;
        this.format = format || {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        };
    }

    getDate(isoString) {
        const date = new Date(isoString);
        return new Intl.DateTimeFormat(this.locale, this.format).format(date);
    }
}
