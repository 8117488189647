export function isModalOpen(id) {
    const allowedModals = [
        'modal-sort',
        'modal-search',
        'modal-compare',
        'modal-history-stats',
        'modal-stats-players',
        'modal-stats-teams',
        'modal-stats-top-fives-players',
        'modal-stats-top-fives-teams'
    ];
    var urlParams = new URLSearchParams(window.location.search);
    return allowedModals.includes(id) && urlParams.has('modal') && urlParams.get('modal') === id;
}

export function updateHistory(id, params) {
    const { pathname, hash } = window.location;
    const urlParams = new URLSearchParams(window.location.search);

    if (params) {
        const modalParams = params.split('=');
        urlParams.set(modalParams[0], modalParams[1]);
    }
    const searchString = urlParams.toString();
    const modal = `modal=${id}`;
    let search = '';

    if (urlParams.has('modal')) {
        search = `?${searchString.replace(/modal=[A-Za-z0-9-]*/g, `${modal}`)}`;
    } else {
        search = `${searchString ? `?${searchString}&` : `?`}${modal}`;
    }
    return (`${pathname}${search}${hash}`);
}

export function removeModalParam() {
    const { pathname, hash } = window.location;
    const urlParams = new URLSearchParams(window.location.search);
    const searchString = urlParams.toString();
    let search = searchString.replace(/(\?|&)?(players|teams|stats)-id=[A-Za-z0-9]*/g, '');

    if (urlParams.has('modal')) {
        search = `${search.replace(/(\?|&)?modal=[A-Za-z0-9-]*/g, '')}`;
        window.history.replaceState({}, '', `${pathname}${search ? `?${search}` : ''}${hash}`);
    }
}

export function getStatsId() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('players-id') || urlParams.get('teams-id') || urlParams.get('stats-id');
}

export function updateTabHistory(tab) {
    const { pathname, hash } = window.location;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('tab', tab);
    const search = urlParams.toString();
    window.history.pushState({}, '', `${pathname}?${search}${hash}`);
}
