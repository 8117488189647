import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { removeModalParam } from 'Helpers/modal-urls';
import IntlProvider from 'I18n/intl-provider';
import Header from 'Components/header';
import Menu from 'Components/menu';
import ErrorMessage from 'Components/error-message';
import { Tracking } from 'Components/tracking';
import ScrollToTop from 'Components/scroll-to-top';
import "./App.scss";

const Home = lazy(() => import('Routes/home'));
const PageList = lazy(() => import('Routes/page/list'));
const IdRoute = lazy(() => import('Routes/page/id-route'));

export default class App extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handlePopState = () => {
            removeModalParam();
        };

        window.addEventListener('popstate', e => this.handlePopState(e));
    }

    render() {
        return <IntlProvider>
            <Tracking id="UA-66889000-1">
                <Router>
                    <div className="app">
                        <Header />
                        <ScrollToTop />
                        <Menu />
                        <Suspense fallback={<Loading />}>
                            <div className="page" role="main">
                                <Switch>
                                    <Route exact path="/:lang([a-zA-Z]{2})?/" render={() => <Home />} />

                                    <Route exact path="/:lang?/players/:id" render={props => <IdRoute {...props} type="players" />} />
                                    <Route exact path="/:lang?/teams/:id" render={props => <IdRoute {...props} type="teams" />} />
                                    <Route exact path="/:lang?/tournaments/:id" render={props => <IdRoute {...props} type="tournaments" />} />

                                    <Route exact path="/:lang?/players" render={props => <PageList {...props} type="players" />} />
                                    <Route exact path="/:lang?/teams" render={props => <PageList {...props} type="teams" />} />
                                    <Route exact path="/:lang?/tournaments" render={props => <PageList {...props} type="tournaments" />} />
                                    <Route component={ErrorMessage} />
                                </Switch>
                            </div>
                        </Suspense>
                        <div className="modal-container" />
                    </div>
                </Router>
            </Tracking>
        </IntlProvider>;
    }
}

export const Loading = () => <div className="slider">
    <div className="line"></div>
    <div className="subline inc"></div>
    <div className="subline dec"></div>
</div>;
