import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { withRouter } from 'react-router-dom';
import t from 'Helpers/translations';
import { updateHistory } from 'Helpers/modal-urls';
import { Loading } from 'Src/App';
import Footer from 'Components/footer';
import { trackingEvent } from 'Components/tracking';
import closeImg from './icons/times-solid.svg';
import style from './modal.module.scss';

class Modal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: props.show
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeFromButton = this.closeFromButton.bind(this);
    }

    componentWillMount() {
        ReactModal.setAppElement('#root');
    }

    componentDidUpdate () {
        window.onpopstate = () => {
            this.closeModal();
        };
    }

    openModal() {
        const { id, params, label, addToHistory = true } = this.props;
        addToHistory && this.props.history.push(updateHistory(id, params));
        this.setState({
            modalIsOpen: true
        },
        () => {
            trackingEvent('event', {
                category: 'Modal',
                action: id.replace(/-/g, ' '),
                label: label || params
            });
        });
    }

    closeModal() {
        this.props.close();
    }

    closeFromButton() {
        const { addToHistory = true } = this.props;
        addToHistory ? window.history.back() : this.closeModal();
    }

    render() {
        const {content, header, type, id, show, loading, feedback = false } = this.props;
        const { modalIsOpen } = this.state;

        if (!id) {
            return null;
        }

        return <ReactModal
            isOpen={modalIsOpen || show}
            onRequestClose={this.closeModal}
            onAfterOpen={this.openModal}
            className={`${style.modal}${type ? ` ${style[type]}` : ``}${loading ? ` ${style.loading}` : ``}`}
            overlayClassName={style.overlay}
            portalClassName="modal-container"
            bodyOpenClassName="modal-open"
            aria={{
                labelledby: "modal-header",
                describedby: "modal_content"
            }}
            closeTimeoutMS={400}>

            <div id="modal-header" className={style.header}>
                {header}
                <button onClick={this.closeFromButton}><img src={closeImg} alt={t('close')} /></button>
            </div>

            {loading && <Loading />}

            <div id="modal_content" className={style.content} tabIndex={type === 'stats' ? 0 : undefined}>
                {content}
            </div>
            {feedback && <Footer />}
        </ReactModal>;
    }
}

export default withRouter(Modal);
