import React, {PureComponent} from 'react';
import { withRouter } from 'react-router-dom';
import t from 'Helpers/translations';
import { LanguageContext } from 'I18n/LanguageContext';
import Cta from 'Components/cta';
import { trackingEvent } from 'Components/tracking';
import InputFieldText from 'Components/input-field-text';
import EntityList from 'Components/entity-list';
import style from './search.module.scss';

class Search extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            searchTerm: '',
            id: null,
            type: null
        };

        this.onSelect = this.onSelect.bind(this);
        this.updateField = this.updateField.bind(this);
        this.submit = this.submit.bind(this);
    }

    onSelect(id, name, type) {
        this.setState({
            searchTerm: name,
            id: id,
            type: type
        });
        this.textInput.focus();
    }

    updateField(e) {
        this.setState({
            searchTerm: e.target.value
        });
    }

    submit(e) {
        e.preventDefault();
        const { close, history, parent } = this.props;
        const { id, type, searchTerm } = this.state;
        const { basePath } = this.context;

        trackingEvent('event', {
            category: `Search (${parent})`,
            action: type,
            label: searchTerm
        });

        close && close();
        history.push(`${basePath}/${type}s/${encodeURI(id).replace(/'/g, '%27')}`);
    }

    render() {
        const { id, type } = this.state;
        const {parent} = this.props;

        return <form onSubmit={this.submit} className={style[parent]}>
            <div className={style.search}>
                <InputFieldText
                    reference={(input) => {
                        this.textInput = input;
                    }}
                    type="large"
                    onChange={this.updateField}
                    ariaLabel={t('search')}
                    placeholder={t(`searchHint`)}
                    value={this.state.searchTerm} />
                <EntityList
                    type={type}
                    onSelect={(id1, name, index, type1) => this.onSelect(id1, name, type1)}
                    term={this.state.searchTerm} />
            </div>
            <Cta button
                type="primary"
                label={t('search')}
                disabled={!id}>{t('search')}</Cta>
        </form>;
    }
}

const WrappedSearch = withRouter(Search);
WrappedSearch.WrappedComponent.contextType = LanguageContext;
export default WrappedSearch;
