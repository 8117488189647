export const defaultLanguage = 'en';

export const supportedLanguages = [{
    language: 'en',
    locale: 'en',
    basePath: '/en',
    flag: 'british'
}, {
    language: 'ga',
    locale: 'ie',
    basePath: '/ie',
    flag: 'irish'
}, {
    language: 'it',
    locale: 'it',
    basePath: '/it',
    flag: 'italian'
}, {
    language: 'ru',
    locale: 'ru',
    basePath: '/ru',
    flag: 'russian'
}];
